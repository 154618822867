
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index4YFnOuzrorex_454_H62PSyPHwOaF7nsZddOn7z3_45hdHoMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/index.vue?macro=true";
import { default as loginDtkZhkSDXSJwifeCY2xJkUqzl_tW924JCoOV9yJ94lUMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/login.vue?macro=true";
import { default as _91token_9343UqT98r8uWkFFndt3nIxSMCduO6b_Gf31F9X3ALZLEMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/[uidb64]/[token].vue?macro=true";
import { default as completeeoP53b8dQLqeICGzB7yz2JxuET2VtGHZUXBP9Va6wAsMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/complete.vue?macro=true";
import { default as done2ae48tbfuhFRyHa6DqUSaXGA6GmYw_LEpsHanhuVqxQMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/done.vue?macro=true";
import { default as indexdvu7J4a9vEvK_45D8MRFbeQovExTSwYmeVGTRvhU8j5jsMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/index.vue?macro=true";
import { default as password_45resetE4e8vc1SaNl3ZagB2KH5_45XSfTozRW4G10oli0Lq5wwoMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset.vue?macro=true";
import { default as passwordxzF1H_450uYDZzA0laBuV6K7YB5CDoR0n4e2giT8HxMXQMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/account/password.vue?macro=true";
import { default as indexU5es_Oxd0W55EeIOyzEbR5aiEeeRZJsd_45_6xTP0X1QEMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/index.vue?macro=true";
import { default as _91id_93eNIKy6Lg0Ah2qmumstOw0ExPxCZrQPfmhd0hYqt4XBEMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/support/conversations/[id].vue?macro=true";
import { default as indexFZJRtUvKrnrkD8ZLMZTTHyKnYNKk7lDqjgaAu1f7sv8Meta } from "/home/runner/work/ticketera/ticketera/admin/pages/support/conversations/index.vue?macro=true";
import { default as conversationslUOusC73aqb0odlXmNBsELXA2Wxqo1orj_452u9a_454U20Meta } from "/home/runner/work/ticketera/ticketera/admin/pages/support/conversations.vue?macro=true";
import { default as indexPss_gGtmf6olxpUqKXRrS7aoYewEfrEfBm54WRFdM7cMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/support/index.vue?macro=true";
import { default as _91id_93Ggqd34Xs0XdRA1YnrdAf0ht_45pIYg3JIqH7vez2IkfTAMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/deposits/[id].vue?macro=true";
import { default as indexiCfoPNEEwYBd0X2lSCVj92s1TvhoQgEuOhUmc2IKSV0Meta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/deposits/index.vue?macro=true";
import { default as change7juPlY4QN0Mm7zBn6EcGk_45l1CvzQUAKh4c1aQQRUgpoMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/[slug]/change.vue?macro=true";
import { default as indexpJ_ciOEY9wqH3SHV5MJMeHFeiAwW9uAbqWInCY_xBAsMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/[slug]/index.vue?macro=true";
import { default as readL5OJ3_aImFILr0G1pS2CMa3e8Efyk6LeNXNHxNTceUEMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/[slug]/read.vue?macro=true";
import { default as addflR5ns_450ziDxADXm12ObEuVeXn7528pr_45_v39O6WV4sMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/add.vue?macro=true";
import { default as indexLbWEu0JE69_evAAhCLsIe8QfSFyjL1g6NVM9Rm_45Yl5YMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/index.vue?macro=true";
import { default as indexOP4Py7ePwz2NqD3UOLrWeWHxxH1DFKBc_45RcOjfvFnawMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/index.vue?macro=true";
import { default as _91id_93wzNjr0kczJk8I86IrYVW7vmub6i9blYJV_Y3pj4UeeEMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/orders/[id].vue?macro=true";
import { default as index0MXumfa0h_bJYPbqXr5ik5m8m_kAY9ajQs_45qhfjQq4UMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/orders/index.vue?macro=true";
import { default as paymentsC17duPHTPNM_45IXrEXHSV6ZaHygjNYZMskfj4CRXL7dUMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/payments.vue?macro=true";
import { default as performersP7cNNbymx2tKPeFhtQnQPwv_45oyG52mahkBsPl6wMW1YMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing/performers.vue?macro=true";
import { default as ticketingu0zfpX1lsglptJUmUoQkM1dWq8OaHnp2pLDblKViCz8Meta } from "/home/runner/work/ticketera/ticketera/admin/pages/ticketing.vue?macro=true";
import { default as changephBV6rp2650OPtBm9X_eLTplQUY_45kyrixFF_45_sJnr3QMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/users/[id]/change.vue?macro=true";
import { default as indexsun_45CjohkjWQ5m1gIfoZMTgxk68E7S_45YJhKW5o1uwLMMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/users/[id]/index.vue?macro=true";
import { default as set_45passwordOtEx9w4YDcBBY87MsH5fofEcPnoBr_45ZaXil04gtS7xoMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/users/[id]/set-password.vue?macro=true";
import { default as addRtA6SUueLNfmrFhkn4q5hM_45YNvjvfEPUFZIcoHART4kMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/users/add.vue?macro=true";
import { default as index3BHdliApSVeE8tt2J12_3LPI1nSKUwNeRs_45WfFtyykQMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/users/index.vue?macro=true";
import { default as usersDyGhciwAzKa8db_45fEqlxK0IOwhsXrY65ppgAk1smNZUMeta } from "/home/runner/work/ticketera/ticketera/admin/pages/users.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: index4YFnOuzrorex_454_H62PSyPHwOaF7nsZddOn7z3_45hdHoMeta || {},
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/index.vue")
  },
  {
    name: "account-login",
    path: "/account/login",
    meta: loginDtkZhkSDXSJwifeCY2xJkUqzl_tW924JCoOV9yJ94lUMeta || {},
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/login.vue")
  },
  {
    name: password_45resetE4e8vc1SaNl3ZagB2KH5_45XSfTozRW4G10oli0Lq5wwoMeta?.name,
    path: "/account/password-reset",
    meta: password_45resetE4e8vc1SaNl3ZagB2KH5_45XSfTozRW4G10oli0Lq5wwoMeta || {},
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset.vue"),
    children: [
  {
    name: "account-password-reset-uidb64-token",
    path: ":uidb64()/:token()",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/[uidb64]/[token].vue")
  },
  {
    name: "account-password-reset-complete",
    path: "complete",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/complete.vue")
  },
  {
    name: "account-password-reset-done",
    path: "done",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/done.vue")
  },
  {
    name: "account-password-reset",
    path: "",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/password-reset/index.vue")
  }
]
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordxzF1H_450uYDZzA0laBuV6K7YB5CDoR0n4e2giT8HxMXQMeta || {},
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/account/password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexU5es_Oxd0W55EeIOyzEbR5aiEeeRZJsd_45_6xTP0X1QEMeta || {},
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/index.vue")
  },
  {
    name: conversationslUOusC73aqb0odlXmNBsELXA2Wxqo1orj_452u9a_454U20Meta?.name,
    path: "/support/conversations",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/support/conversations.vue"),
    children: [
  {
    name: "support-conversations-id",
    path: ":id()",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/support/conversations/[id].vue")
  },
  {
    name: "support-conversations",
    path: "",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/support/conversations/index.vue")
  }
]
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/support/index.vue")
  },
  {
    name: ticketingu0zfpX1lsglptJUmUoQkM1dWq8OaHnp2pLDblKViCz8Meta?.name,
    path: "/ticketing",
    meta: ticketingu0zfpX1lsglptJUmUoQkM1dWq8OaHnp2pLDblKViCz8Meta || {},
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing.vue"),
    children: [
  {
    name: "ticketing-deposits-id",
    path: "deposits/:id()",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/deposits/[id].vue")
  },
  {
    name: "ticketing-deposits",
    path: "deposits",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/deposits/index.vue")
  },
  {
    name: "ticketing-events-slug-change",
    path: "events/:slug()/change",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/[slug]/change.vue")
  },
  {
    name: "ticketing-events-slug",
    path: "events/:slug()",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/[slug]/index.vue")
  },
  {
    name: "ticketing-events-slug-read",
    path: "events/:slug()/read",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/[slug]/read.vue")
  },
  {
    name: "ticketing-events-add",
    path: "events/add",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/add.vue")
  },
  {
    name: "ticketing-events",
    path: "events",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/events/index.vue")
  },
  {
    name: "ticketing",
    path: "",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/index.vue")
  },
  {
    name: "ticketing-orders-id",
    path: "orders/:id()",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/orders/[id].vue")
  },
  {
    name: "ticketing-orders",
    path: "orders",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/orders/index.vue")
  },
  {
    name: "ticketing-payments",
    path: "payments",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/payments.vue")
  },
  {
    name: "ticketing-performers",
    path: "performers",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/ticketing/performers.vue")
  }
]
  },
  {
    name: usersDyGhciwAzKa8db_45fEqlxK0IOwhsXrY65ppgAk1smNZUMeta?.name,
    path: "/users",
    meta: usersDyGhciwAzKa8db_45fEqlxK0IOwhsXrY65ppgAk1smNZUMeta || {},
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/users.vue"),
    children: [
  {
    name: "users-id-change",
    path: ":id()/change",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/users/[id]/change.vue")
  },
  {
    name: "users-id",
    path: ":id()",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/users/[id]/index.vue")
  },
  {
    name: "users-id-set-password",
    path: ":id()/set-password",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/users/[id]/set-password.vue")
  },
  {
    name: "users-add",
    path: "add",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/users/add.vue")
  },
  {
    name: "users",
    path: "",
    component: () => import("/home/runner/work/ticketera/ticketera/admin/pages/users/index.vue")
  }
]
  }
]